import { default as aboutIYttGxXltYMeta } from "/workspace/pages/about.vue?macro=true";
import { default as contact8zxLAXYQX4Meta } from "/workspace/pages/contact.vue?macro=true";
import { default as index9S03gOyIVcMeta } from "/workspace/pages/index.vue?macro=true";
import { default as _91id_93z7dKbZaNF8Meta } from "/workspace/pages/jobs/[id].vue?macro=true";
import { default as indexgb2iIOVW1vMeta } from "/workspace/pages/jobs/index.vue?macro=true";
import { default as privacy_45policyJuUuuPjb3dMeta } from "/workspace/pages/privacy-policy.vue?macro=true";
import { default as recruitmentlSerrPkDdLMeta } from "/workspace/pages/recruitment.vue?macro=true";
import { default as terms_45and_45conditionsD3pGvh9rLRMeta } from "/workspace/pages/terms-and-conditions.vue?macro=true";
export default [
  {
    name: aboutIYttGxXltYMeta?.name ?? "about",
    path: aboutIYttGxXltYMeta?.path ?? "/about",
    meta: aboutIYttGxXltYMeta || {},
    alias: aboutIYttGxXltYMeta?.alias || [],
    redirect: aboutIYttGxXltYMeta?.redirect || undefined,
    component: () => import("/workspace/pages/about.vue").then(m => m.default || m)
  },
  {
    name: contact8zxLAXYQX4Meta?.name ?? "contact",
    path: contact8zxLAXYQX4Meta?.path ?? "/contact",
    meta: contact8zxLAXYQX4Meta || {},
    alias: contact8zxLAXYQX4Meta?.alias || [],
    redirect: contact8zxLAXYQX4Meta?.redirect || undefined,
    component: () => import("/workspace/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: index9S03gOyIVcMeta?.name ?? "index",
    path: index9S03gOyIVcMeta?.path ?? "/",
    meta: index9S03gOyIVcMeta || {},
    alias: index9S03gOyIVcMeta?.alias || [],
    redirect: index9S03gOyIVcMeta?.redirect || undefined,
    component: () => import("/workspace/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93z7dKbZaNF8Meta?.name ?? "jobs-id",
    path: _91id_93z7dKbZaNF8Meta?.path ?? "/jobs/:id()",
    meta: _91id_93z7dKbZaNF8Meta || {},
    alias: _91id_93z7dKbZaNF8Meta?.alias || [],
    redirect: _91id_93z7dKbZaNF8Meta?.redirect || undefined,
    component: () => import("/workspace/pages/jobs/[id].vue").then(m => m.default || m)
  },
  {
    name: indexgb2iIOVW1vMeta?.name ?? "jobs",
    path: indexgb2iIOVW1vMeta?.path ?? "/jobs",
    meta: indexgb2iIOVW1vMeta || {},
    alias: indexgb2iIOVW1vMeta?.alias || [],
    redirect: indexgb2iIOVW1vMeta?.redirect || undefined,
    component: () => import("/workspace/pages/jobs/index.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyJuUuuPjb3dMeta?.name ?? "privacy-policy",
    path: privacy_45policyJuUuuPjb3dMeta?.path ?? "/privacy-policy",
    meta: privacy_45policyJuUuuPjb3dMeta || {},
    alias: privacy_45policyJuUuuPjb3dMeta?.alias || [],
    redirect: privacy_45policyJuUuuPjb3dMeta?.redirect || undefined,
    component: () => import("/workspace/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: recruitmentlSerrPkDdLMeta?.name ?? "recruitment",
    path: recruitmentlSerrPkDdLMeta?.path ?? "/recruitment",
    meta: recruitmentlSerrPkDdLMeta || {},
    alias: recruitmentlSerrPkDdLMeta?.alias || [],
    redirect: recruitmentlSerrPkDdLMeta?.redirect || undefined,
    component: () => import("/workspace/pages/recruitment.vue").then(m => m.default || m)
  },
  {
    name: terms_45and_45conditionsD3pGvh9rLRMeta?.name ?? "terms-and-conditions",
    path: terms_45and_45conditionsD3pGvh9rLRMeta?.path ?? "/terms-and-conditions",
    meta: terms_45and_45conditionsD3pGvh9rLRMeta || {},
    alias: terms_45and_45conditionsD3pGvh9rLRMeta?.alias || [],
    redirect: terms_45and_45conditionsD3pGvh9rLRMeta?.redirect || undefined,
    component: () => import("/workspace/pages/terms-and-conditions.vue").then(m => m.default || m)
  }
]