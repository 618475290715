<template>
  <div id="app">
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>
<script setup>
  useHead({
    title: 'Home',
    titleTemplate: '%s - Early Years Recruitment',
    link: [
      { rel: 'preconnect', href: 'https://fonts.googleapis.com' },
      { rel: 'preconnect', href: 'https://fonts.gstatic.com' },
      {
        rel: 'stylesheet',
        href: 'https://fonts.googleapis.com/css2?family=Work+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap"',
      },
    ],
    meta: [
      {
        key: 'description',
        name: 'description',
        content:
          'Recruitment Specialists for Early Years Staff and Education Sectors. Whether you are seeking outstanding Teaching or Early Years Staff or searching for your next career move we can help you.',
      },
      {
        key: 'keywords',
        name: 'keywords',
        content:
          'early years, recruitment, day care, education, teacher, teaching, children, kids, schools, nurseries, care',
      },
      {
      key: 'og:title',
      property: 'og:title',
      content: 'Early Years Recruitment',
    },
    {
      key: 'og:description',
      property: 'og:description',
      content:
        'Recruitment Specialists for Early Years Staff and Education Sectors. Whether you are seeking outstanding Teaching or Early Years Staff or searching for your next career move we can help you.',
    },
    {
      key: 'og:image',
      property: 'og:image',
      content: 'https://early-years.com/_nuxt/logo-light.88ce728c.png',
    },
    {
      key: 'og:image:secure_url',
      property: 'og:image:secure_url',
      content: 'https://early-years.com/_nuxt/logo-light.88ce728c.png',
    },
    {
      key: 'og:image:alt',
      property: 'og:image:alt',
      content: 'Early Years Recruitment',
    },
    ]
  })
</script>
